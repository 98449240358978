const publicKey = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAtTKzCgmch5HaYB8mFUHG
1NBnp5TO7OyyxxOZpKTp7IbirL+Mn5Acf/CTDtFemySBRwUF3TUMqKThxUSnGByv
eynleIB9tYehxZbiSp1xoWf/MkWGxe3aWzHjVOqOdToJMtxgFkYBna41dgUoskxO
7o5PIjXABuSKHoNMsaguLk6rUpom7I5D/YD6kh5DDuXtFA62Lxm3wOSsBFlyi44I
8AhOSuL3Eu9/bVNrGWbV6+L69A8WLnSmoKYl6yG3bYPYah73FuGjqBPv4x1chpU2
X66Df/xA7bnsvPVgCO+Noa5Fx//MlDQzust5p2rZ6EvIm7GrC+GIJwhWaB7U4bc4
q57Q7fSZTrG8Yq4jKAjSoVk8Y0lQEXVp0BLYEHCAGWzIOHY+cS7Ya39+QremjPSC
cBdTxUDDms8HS5qwHRht/OouQgUMS1QLGtSvQRhsAStM2uqLrEIxE8Hc84H0xBCx
Maf1Yaa1kIfsVsACJ29mSVHQc9/PeYWN2TwlU2BDHa8CnjFNqBacgIsYz+BQgzzn
bNcg8rMPTkyj0D4rnBJcXPqoK/flKGcj9InLNaTIfL7qztu75yPPX1b/L4gkITsP
dy9hyWcNMauhb0iYYlrExBiDB29NMEX/S7h5dvqu5FhQpbqgQA43U4V//XWkKjqY
pAE9boKfg/mDTTIx9nNFvwUCAwEAAQ==
-----END PUBLIC KEY-----`;
const _publicKey = publicKey;
export { _publicKey as publicKeyV1 };
