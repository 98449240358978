import palette from "../palette";

const styles = {
  root: {
    color: palette.icon,
    minWidth: 32,
  },
};

export default styles;
