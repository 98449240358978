const publicKey = `-----BEGIN PUBLIC KEY-----
MIIDojANBgkqhkiG9w0BAQEFAAOCA48AMIIDigKCA4EA33g4nSXab775bAzVQjyi
4TjEB6fRIPqH20DoN/RtxAakDvE2hxmbOePS27o3WuvNSIEYH7pEIrzEbvCVTOTP
2fWsyYGlQMYVnFjknroISdbGvXOvqZ+HzbH8Of5dhF6mJ1Nrmd3lDdRylAMabPmB
dH47Z/+hthSBJ3S9hACk1PcR9oli3Ly/8s15Su8MM5fELE2JY/oLzjan4k1ZNIeX
GCbo4Q2IEJxH52SRufRsiLnF5RuMGDBOySpKIwTG2vEJD89kzTu/jqLTpL5NQThy
bUpL8AE8ZSCJNMyaMDNBHrFz6sr310YM2t3sbEnkI76BvO6C7A7DDuhsNmJaM595
UQ+o/HHkp+zuFBc1VbG41w2QGCysF9/x0OJXnY9buuVEMj7sEqTSbIhFAoTUf4jQ
IZH1wamTb5ffY9Cq7u+X+m0x25BhgxwUF92iTBTj42p6NsJGYIi36lNRWj+ey16v
QxVy/1KY0aUpQbnjY3jRdHYUH1xsUBRfy+gUV9fz1k+XsdnHNEg25LoxQVKR8Bmd
8acMoMAWsYKFcz1LICofPV9jU3p5GDxrac002i+4sYEO7FhQXCnAueU6noo+zaoa
hNRAYDZ3ancy/MpixSi1dfInok7Q35KG5F0VpHrXSkkxRL95d9G+mAU2c2t2oZGB
Ffg9OyRWOwlQJe7O0B/BgosI+JR2sczattgT4v5F6rxxc7XlKEm+dRpDDZQ+1cGw
/JAgun5YV44AvkwegypojmkSinIHrBJgDO2m0fs6FmTDf7gdyEXISdK5cLNgzqaV
Z2v8XMFfCrGDHJLQIaKRaSARBYDZaR4NOJmmbotGswnEo0DOPFyvRRcVuNLdilYz
W9zr7nOmqCsGpugyhjHfpxeMHNL5vOeXZaezK0pdOhkhme/UDVjG9y8U1FETYtUa
8R650ymNpqo8n2C+eG9phm9MjF/wgris6ydsvfflc49J+u5ggzcTJLI+jXLBn3wR
rQyYaGubZ1vrwt7fT5YV3pzvev7YnMBuXs0EBQzptBxzfKN0vF9bz9Id8JE1j2ab
lqDWKysdplafIIeR+X1R/D0jftVERqFXiFAV87zS8+ohJuFO7+7sS1TSjFzIkaVS
vCdNgHX5MrAMAYrQAObDfX0FlZQ5rIsc2MSttubjqOQ+VyDOExuR3WAR0RiQC3yk
o8fjzlo3KYwvWkaGwMcLFTkCAwEAAQ==
-----END PUBLIC KEY-----`;
const _publicKey = publicKey;
export { _publicKey as publicKey };
