import React, { useState } from "react";
import QrReader from "@cbmdf/react-qr-scanner";
import crypto from "crypto";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import BRASAO from "./assets/BRASAO.png";
import DetalheIdentidade2 from "./DetalheIdentidade2";
import Typography from "@material-ui/core/Typography";
import ProjetoIncendio20 from "./ProjetoIncendio2.0";
import ProjetoIncendio21 from "./ProjetoIncendio2.1";
var ascii85 = require("ascii85");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    maxWidth: "360px",
    width: "360px",
  },
}));

const { publicKey } = require("./public_key");
const { publicKeyV1 } = require("./public_keyV1");

const QRCodeScanner = () => {
  const classes = useStyles();

  const [dado, setDado] = useState(false);
  const [showDoc, setShowDoc] = useState();

  function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const decryptString = (encrypted, simular = false) => {
    try {
      let decrypted;
      if (simular) {
        decrypted = encrypted;
      } else {
        let encrypted2;
        if (isJson(encrypted)) {
          let jsonDecrypted = decryptV1(encrypted);

          decrypted =
            jsonDecrypted.d + ";" + jsonDecrypted.h + ";" + jsonDecrypted.t;
        } else {
          encrypted2 = ascii85.decode(encrypted);
          decrypted = crypto.publicDecrypt(publicKey, encrypted2);
        }
      }

      let meuNovoDadoQRCODE = decrypted.toString().split(";");

      setShowDoc(meuNovoDadoQRCODE[0]);

      setDado(meuNovoDadoQRCODE);
    } catch (e) {
      console.error(e);
    }
  };

  const handleScan = (data, data2) => {
    if (data) {
      decryptString(data);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const decryptV1 = (input) => {
    const jsonInput = JSON.parse(input);

    const data = jsonInput.data;

    // Cria um array a partir do data CSV
    var arrCrypt = data.split(",");

    // Decriptografa cada parte usando forEach (em vez de map)
    let output = "";

    arrCrypt.forEach((chunk) => {
      // Alterado para forEach
      let encrypted = Buffer.from(chunk, "base64");
      console.log(publicKeyV1);
      const decrypted = crypto.publicDecrypt(publicKeyV1, encrypted);
      output = output.concat(decrypted);
    });

    return JSON.parse(output);
  };

  const returnQRCODE = () => {
    const previewStyle = {
      height: 340,
      width: 340,
    };
    if (!dado) {
      var pjson = require("../package.json");
      return (
        <div className={classes.root}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={3}>
              <img
                className={classes.img}
                alt="Brasão"
                src={BRASAO}
                width="75%"
              />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h5" align="center">
                SisCAD - Certificador de Autenticidade de Documentos do CBMDF v.
                {pjson.version}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} className={classes.qrcode}>
              <QrReader
                style={previewStyle}
                delay={500}
                onError={handleError}
                onScan={handleScan}
                facingMode="environment"
                maxImageSize={3000}
              />
            </Grid>
          </Grid>
        </div>
      );
    } else {
      const tipo = showDoc.replace(/"/g, "");

      switch (tipo) {
        case "2.0":
          return <ProjetoIncendio20 dado={dado} setDado={setDado} />;
        case "2.1":
          return <ProjetoIncendio21 dado={dado} setDado={setDado} />;
        case "3":
          return <DetalheIdentidade2 dado={dado} setDado={setDado} />;
        default:
          alert("Documento não reconhecido.");
          break;
      }
    }
  };

  return <div className={classes.root}>{returnQRCODE()}</div>;
};

export default QRCodeScanner;
