import palette from "../palette";
import typography from "../typography";

const styles = {
  root: {
    ...typography.body1,
    borderBottom: `1px solid ${palette.divider}`,
  },
};

export default styles;
